import React, {useState} from "react";
import {IChallenge} from "../../../types/IChallenge";
import {IChallengeStage} from "../../../types/IChallengeStage";
import {StageButtonJury} from "./StageButtonJury";
import {Help} from "../../common/Help";
import {CONFIG} from "../../../../CONFIG";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";

export const JuryStages:React.FC<{challenge: IChallenge}> = ({challenge}) => {

    const [error, setError] = useState("");

    return (
        <div className="platform-background">
            <Help challenge={challenge}/>
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                        <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                    <source src={require("../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
                <ProfileIndividualHeader newPreview={""}/>
                <main className="content">
                    <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                    <div className="main-list-center-comand">
                        <div className="my-challenge">
                            <a href={challenge.site} target="_blank" className="link-landing"></a>
                            <div className="user-info">
                                <p className="fio-or-name" style={{cursor: "pointer"}}>{challenge.name}</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href="#" className="link-nav-center-item">Этапы</a>
                                </li>
                            </ul>
                        </div>
                        <div className="challenge-info">
                            <div className="first-message">
                                <p className="city">
                                    Нажмите на активный этап, чтобы ознакомиться с заданием. <a href="https://youtu.be/D5h9lhOizfk" target="_blank" className="active-link">Видеоинструкция</a>  по работе с разделом
                                </p>
                            </div>

                            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
                            {challenge.stages && challenge.stages.map((s: IChallengeStage, index) =>
                                <StageButtonJury stage={s} key={s.id} index={index+1}/>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}